import React, { useState, useEffect, lazy, Suspense } from 'react';
import '../../features/features.css';
import '../../form/price/BookingForm.css';

// Lazy load SRSubmissionResults component
const SRSubmissionResults = lazy(() => import('./SRSubmissionResults'));

const imagePaths = {  
  bannerImage: require("../../assets/img/home_banner_img.png"),
  whatsAppImage: require("../../assets/img/whatsapp.png"),
  callMobileImage: require("../../assets/img/callMobile.png")
};

const SRCustomForm = ({ BannerTitle = "Plan Your Perfect Journey with Us!", BannerImg }) => {
  const [cities, setCities] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState({ fromCity: false, toCity: false });
  const [submissionResponse, setSubmissionResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [journeyDetails, setJourneyDetails] = useState(null);

  const [formData, setFormData] = useState({
    fromCity: '',
    toCity: '',
    pickUpDate: '',
    returnDate: '',
    mobNumber: '',
    tripType: 'oneWay',
  });

  // Prefetch banner image
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'prefetch';
    link.href = BannerImg || imagePaths.bannerImage;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, [BannerImg]);

  // Fetch cities as user types
  useEffect(() => {
    if (formData.fromCity && showSuggestions.fromCity) {
      fetchCities(formData.fromCity, 'fromCity');
    }
    if (formData.toCity && showSuggestions.toCity) {
      fetchCities(formData.toCity, 'toCity');
    }
  }, [formData.fromCity, formData.toCity, showSuggestions]);

  const fetchCities = async (query, field) => {
    try {
      const response = await fetch(`https://srcartravels.in/cities?search=${query}`);
      const data = await response.json();
      setCities(data.cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'fromCity' || name === 'toCity') {
      setShowSuggestions(prev => ({ ...prev, [name]: true }));
    }
  };

  const handleCitySelect = (cityName, field) => {
    setFormData(prev => ({ ...prev, [field]: cityName }));
    setShowSuggestions({ fromCity: false, toCity: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const minLoadingTime = 1000; // Ensure spinner shows at least for 1 second
    const startTime = Date.now();

    try {
      const response = await fetch('https://srcartravels.in/submittedData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setSubmissionResponse(data);
      setJourneyDetails({
        fromCity: formData.fromCity,
        toCity: formData.toCity,
        pickUpDate: formData.pickUpDate,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, minLoadingTime - elapsed);
      setTimeout(() => {
        setIsLoading(false);
      }, remaining);
    }
  };

  const renderCitySuggestions = (fieldName) => {
    return showSuggestions[fieldName] && cities.length > 0 && (
      <div className="list-group position-absolute w-100" style={{ zIndex: 1 }}>
        {cities.map((city, index) => (
          <button
            type="button"
            key={index}
            className="list-group-item list-group-item-action"
            onClick={() => handleCitySelect(city.cityName, fieldName)}
          >
            {city.cityName}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <div>  
        <div className="callus-btn">
          <a href="tel:8886556632" target="_blank" rel="noopener noreferrer">
            <img
              src={imagePaths.callMobileImage}
              className="banner-backdrop d-block w-100"
              alt="Call Us"
              loading="lazy"
            />
            Call Us
          </a>
        </div>

        <div className="whatsapp-btn">
          <a href="https://wa.me/9346397115" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-whatsapp"></i> WhatsApp
          </a>
        </div>

        <img
          src={BannerImg || imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          alt="Car Travel Booking"
          loading="lazy"
        />
        <h1
          className="mb-3 text-center banner-title-h1"
          style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600', color: '#2c3e50' }}
        >
          {BannerTitle}
        </h1>
        <div className="py-3">
          <form id="myForm" onSubmit={handleSubmit}>
            <div className="booking-form">
              <div className="radio-buttons">
                <label>
                  <input
                    type="radio"
                    name="tripType"
                    value="oneWay"
                    id="oneWay"
                    checked={formData.tripType === 'oneWay'}
                    onChange={handleInputChange}
                  />
                  <span className="px-2"><b>One Way Trip</b></span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="tripType"
                    value="roundTrip"
                    id="roundTrip"
                    checked={formData.tripType === 'roundTrip'}
                    onChange={handleInputChange}
                  />
                  <span className="px-2"><b>Round Trip</b></span>
                </label>
              </div>

              <div>
                <label className="px-1 pb-3">
                  <b>Pickup Location</b>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="fromCity"
                      name="fromCity"
                      placeholder="Enter your starting city"
                      required
                      value={formData.fromCity}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                    <div>
                      {formData.fromCity && renderCitySuggestions('fromCity')}
                    </div>
                  </div>
                </label>
                <label className="px-1 pb-3">
                  <b>Destination</b>
                  <div>
                    <div className="input-group position-relative">
                      <input
                        type="text"
                        className="form-control"
                        id="toCity"
                        name="toCity"
                        placeholder="Enter your destination"
                        required
                        value={formData.toCity}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      {formData.toCity && renderCitySuggestions('toCity')}
                    </div>
                  </div>
                </label>
                <label className="px-1 pb-3">
                  <b>Pickup Date & Time</b>
                  <div>
                    <input
                      type="datetime-local"
                      id="pickUpDate"
                      name="pickUpDate"
                      required
                      value={formData.pickUpDate}
                      onChange={handleInputChange}
                    />
                  </div>
                </label>
                <label className={formData.tripType === 'oneWay' ? 'disabled pb-3' : 'pb-3'}>
                  <b>Return Date & Time</b>
                  <div>
                    <input
                      type="datetime-local"
                      id="returnDate"
                      name="returnDate"
                      required
                      value={formData.returnDate}
                      onChange={handleInputChange}
                      disabled={formData.tripType === 'oneWay'}
                    />
                  </div>
                </label>
                <label className="px-1 pb-3">
                  <b>Your Mobile Number</b>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="mobNumber"
                      name="mobNumber"
                      placeholder="Enter your 10-digit mobile number"
                      pattern="[6789][0-9]{9}"
                      autoComplete="off"
                      required
                      value={formData.mobNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </label>
              </div>

              <div id="getPriceID" className="d-flex justify-content-center py-4">
                <button
                  className="btn btn-info text-white"
                  type="submit"
                  style={{
                    backgroundColor: '#007BFF',
                    border: 'none',
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}
                >
                  GET PRICE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Loading Spinner with Improved Messaging */}
      {isLoading && (
  <div className="loading-container">
    <div className="spinner"></div>
    <div className="loading-text">
      Hang on… We are finding you the best price with no extra charges!
    </div>
    <div className="small-text">
      Our prices are as low as possible. Call us directly for the best deal.
    </div>
  </div>
)}

      {journeyDetails && (
        <div className="text-center my-4 search-result-text">
          <p>
            Great news! Your journey from <strong>{journeyDetails.fromCity}</strong> to <strong>{journeyDetails.toCity}</strong> on <strong>{journeyDetails.pickUpDate}</strong> has been processed.
          </p>
          <p>
            We guarantee the best price with no extra charges. For even lower rates and exclusive deals, call us directly now!
          </p>
        </div>
      )}

      {(formData.fromCity.includes('Hyderabad') || formData.toCity.includes('Hyderabad')) && (
        <div className="blinkDescr text-center" style={{ color: '#007BFF', fontWeight: 'bold', fontSize: '20px', marginBottom: '20px' }}>
          🚗 Special Offer: Trips between Vijayawada & Hyderabad starting at just ₹5250! Call us now!
        </div>
      )}

      {submissionResponse && (
        <Suspense fallback={
          <div className="loading-container">
            <div className="spinner"></div>
            <div className="loading-text">
              Loading best deals...
            </div>
          </div>
        }>
          <SRSubmissionResults data={submissionResponse} />
        </Suspense>
      )}
    </>
  );
};

SRCustomForm.defaultProps = {
  BannerImg: imagePaths.bannerImage,
};

export default SRCustomForm;
