import React from 'react';
import { Helmet } from 'react-helmet';
import SRCustomForm from "../components/common/SRCustomForm";
import SRSubmissionResults from '../components/common/SRSubmissionResults';
import SRSpecialPackages from "../components/common/SRSpecialPackages";
import SRCustomerReviews from "../components/common/SRCustomerReviews";
import SRFAQs from "../components/common/SRFAQs";
import SRInfoServices from "../components/common/SRInfoServices";
import SRCarTravelsInfo from "../components/common/SRCarTravelsInfo";
import '../features/features.css';
import VijayawadaCarRentalsExperience from '../KeyWordsCoveredContent/VijayawadaCarRentalsExperience';
import BestCarTravelsVijayawada from '../KeyWordsCoveredContent/BestCarTravelsVijayawada';
import HomePageUniqueFeatures from '../KeyWordsCoveredContent/HomePageUniqueFeatures';
import SRContactForm from '../components/common/SRContactForm';

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Car Travels in Vijayawada | Best Cabs in Vijayawada | SR Car Travels</title>
                <meta name="description" content="Experience the best car travels in Vijayawada with SR Car Travels. Discover the city's hidden gems and travel in comfort. Book your trip today!" />
                <link rel="canonical" href="https://srcartravels.in/" />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Car Travels in Vijayawada, Car Travels Vijayawada, Car Travel in Vijayawada, Best Car Travels in Vijayawada, Car Travellers Near Me, Car Rentals Vijayawada" />
                
                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Car Travels in Vijayawada | Car Rentals in Vijayawada |SR Car Travels" />
                <meta property="og:description" content="Discover seamless car travels in Vijayawada and beyond with SR Car Travels. Whether you need car rentals, cabs, or personalized travel packages, we've got you covered." />
            </Helmet>

            <SRCustomForm 
  BannerTitle="Affordable Car Travel Services - Serving All Cities with No Extra Charges"
  SecondaryBannerTitle="Hyderabad Car Travel Services - All-Inclusive, No Extra Charges, Starting at ₹5500"
/>
            {/* H1 Tag for SEO */} 
            <div className="homepage-contents-container">  
            <VijayawadaCarRentalsExperience />
            <SRSubmissionResults />
            <SRSpecialPackages />
            <br />
            <BestCarTravelsVijayawada />
            {/* <HomePageUniqueFeatures /> */}
            <SRCustomerReviews />
            {/* <SRInfoServices /> */}
            {/* <SRCarTravelsInfo /> */}
            <SRFAQs />
            {/* <SRCarTravelsInfo /> */}
            <SRContactForm />
            </div>
        </>
    );
};

export default HomePage;
